import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {DomSanitizer} from "@angular/platform-browser";

export const get_school_path = () => {
	let path = location.pathname.split('/')[1];
	if(['tc', 'sc', 'en'].includes(path)) {
		return "";
	}
	return path;
}

export const get_school_id = () => {
	// let host_arr = location.hostname.split('.');
	//
	// if (host_arr.indexOf('straw') > -1) {
	// 	return 43638;
	// } else if (host_arr.indexOf('pthyxs') > -1) {
	// 	return 1726;
	// } else if (host_arr.indexOf('fungwing') > -1) {
	// 	return 37366;
	// } else if (host_arr.indexOf('ban-banurverygood') > -1) {
	// 	return 52754;
	// }

	// base on path
	// let url = location.href;
	let school_path = get_school_path();
	if (school_path === 'joylearn') {
		return 272;
	} else if (school_path === 'joylearn_pthyxs') {
		return 1726;
	} else if (school_path === 'pth') {
		return 1726;
	} else if (school_path === 'fungwing') {
		return 37366;
	} else if (school_path === 'ban-banurverygood') {
		return 52754;
	} else if (school_path === 'straw') {
		return 43638;
	} else if (school_path === '' || school_path === 'online_shop') {
		return 1568;
	}
	// if (url.indexOf('/joylearn/') > -1) {
	// 	return 272;
	// } else if (url.indexOf('/joylearn_pthyxs/') > -1) {
	// 	return 1726;
	// } else if (url.indexOf('/pth/') > -1) {
	// 	return 1726;
	// } else if (url.indexOf('/fungwing/') > -1) {
	// 	return 37366;
	// } else if (url.indexOf('/ban-banurverygood/') > -1) {
	// 	return 52754;
	// } else if (url.indexOf('/straw/') > -1) {
	// 	return 43638;
	// }

	// if (location.port != '') {
	// 	// return 1726;
	// 	return 272;
	// }
}

export const get_app_id = () => {
	// let host_arr = location.hostname.split('.');

	// if (host_arr.indexOf('straw') > -1) {
	// 	return 'straw';
	// } else if (host_arr.indexOf('pthyxs') > -1) {
	// 	return 'pthyxs';
	// } else if (host_arr.indexOf('fungwing') > -1) {
	// 	return 'fungwing';
	// } else if (host_arr.indexOf('ban-banurverygood') > -1) {
	// 	return 'ban-banurverygood';
	// }

	// base on path
	let url = location.href;

	if (url.indexOf('/joylearn/') > -1) {
		return 'rainbowone';
	} else if (url.indexOf('/joylearn_pthyxs/') > -1) {
		return 'pthyxs';
	} else if (url.indexOf('/pth/') > -1) {
		return 'pthyxs';
	} else if (url.indexOf('/fungwing/') > -1) {
		return 'fungwing';
	} else if (url.indexOf('/ban-banurverygood/') > -1) {
		return 'ban-banurverygood';
	} else if (url.indexOf('/straw/') > -1) {
		return 'straw';
	}

	// if (location.port != '') {
	// 	// return 'pthyxs';
	// 	return 'rainbowone';
	// }

}

@Injectable({providedIn: 'root'})
export class SchoolService {

	_school_id = null;
	_app_id = null;
	_safe_logo_url = null;

	has_logo = null;
	logo_url = null;
	favicon_url = null;
	school_name = null;


	constructor(
		public api: ApiService,
		public dom: DomSanitizer,
	) {
		this.init()
	}

	init() {
		this._app_id = get_app_id();
		this._school_id = get_school_id();
		this.init_logo();
	}

	async init_logo() {
		let response = await this.api.post('/api/school/info', {
			school_id: this._school_id,
		})

		if (response.success) {
			this.logo_url = response.logo_url;
			this.favicon_url = response.favicon_url;
			if (this.favicon_url) {
				document
					.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"]')
					.forEach((el) => {
						el.setAttribute('href', this.favicon_url);
					});
			}
			this.school_name = response.school_name;
			this.has_logo = !!this.logo_url;
		}
	}

	get school_id() {
		return this._school_id;
	}

	get app_id() {
		return this._app_id;
	}

	get safe_logo_url() {
		if (this.has_logo) {
			if (!this._safe_logo_url) {
				this._safe_logo_url = this.dom.bypassSecurityTrustResourceUrl(this.logo_url);
			}
			return this._safe_logo_url;
		}
		return null;
	}
}