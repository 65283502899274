export const environment = {
	production: true,
	type:"production",
	title: "Online Shop",
	baseHref: "/",
	version: require('../../package.json').version,
	versions: require('../../package.json').versions,
	serverHost: "https://www.rainbowone.shop",
	apiUrl: "https://www.rainbowone.shop/backend",
	qfpay_url: "https://openapi-hk.qfapi.com/checkstand/#/?",
	qfpay_appcode: "1B9B12BF2B524175BE53C3DA8304BAF6",
	qfpay_apikey: "59A80AA9AF9E4C0F9770464A04C590F9",
};
