import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminAuthGuard} from './service/admin-auth-guard.service';
import {Shop_routes} from "./coreModule/shop/shop_routes";
import {Admin_routes} from "./coreModule/admin/admin_routes";
import {APP_BASE_HREF} from "@angular/common";
import {get_school_id} from "./service/school.service";

let school_id = get_school_id();

/**
 * if school_id is 1568, this means the user is accessing our company's online shop
 * if school_id is not 1568, this means the user is accessing a school's online shop
 */
const routes: Routes = (school_id !== 1568 ? [
	{path: ':school/:lang/admin', children: Admin_routes},
	{path: ':school/:lang', children: Shop_routes},
	{path: ':school', redirectTo: ':school/tc', pathMatch: 'full',},
] : [
	{path: ':lang/admin', children: Admin_routes},
	{path: ':lang', children: Shop_routes},
	{path: '', redirectTo: 'tc', pathMatch: 'full',},
]);

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [
		AdminAuthGuard,
		{provide: APP_BASE_HREF, useValue: `/`}
	]
})

export class AppRoutingModule {
}
